import * as React from 'react';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { SubmitContactForm, SubmitContactFormVariables } from '../data';
import { Layout, SEO, PageHero, GraphQLBlock } from '../blocks';
import { FormikInput, Button } from '../components';

const contactIcon = require('../assets/icons/icon-message-bubbles.svg');

interface State {
  showForm: boolean;
  error: boolean;
  height: number;
}

interface FormValues {
  name: string;
  company: string;
  email: string;
  phone: string;
  message: string;
}

export class Contact extends React.Component<{}, State> {
  state: State = { showForm: true, error: false, height: 0 };

  formContainer: HTMLDivElement;

  render() {
    return (
      <Layout>
        <SEO title={`NuWave Commerce | Contact Us`} />
        <PageHero title={`Contact Us`} subTitle={``} />
        <section className="bg-white">
          <div className="container mx-auto mx-0 py-0 md:py-12">
            <div className="flex flex-wrap-reverse">
              <div className="w-full md:w-1/3 bg-purple p-8 lg:py-8 lg:px-12">
                <div className="mb-8">
                  <img src={contactIcon} style={{ width: 60 }} />
                </div>
                <h3 className="text-white font-normal font-serif uppercase tracking-wide text-3xl leading-loose mb-8">
                  Let's build something amazing
                </h3>
                <p className="text-white">
                  Working on your next big idea? We can help make that happen.
                </p>
              </div>
              {this.state.showForm && (
                <div
                  id="form-container"
                  className="w-full md:w-2/3 p-4 md:p-8 md:pl-12"
                  ref={div => (this.formContainer = div)}
                >
                  <Mutation<SubmitContactForm, SubmitContactFormVariables>
                    mutation={CONTACT_MUTATION}
                  >
                    {submit => (
                      <div className="contact-form">
                        <Formik<FormValues>
                          initialValues={{
                            company: '',
                            message: '',
                            phone: '',
                            email: '',
                            name: '',
                          }}
                          onSubmit={(input, { setSubmitting }) => {
                            this.setState({ error: false });
                            setSubmitting(true);
                            submit({ variables: { input: input } })
                              .then(result => {
                                setSubmitting(false);
                                this.setState({
                                  showForm: false,
                                  height: this.formContainer.clientHeight,
                                });
                              })
                              .catch(response => {
                                this.setState({ error: true });
                                setSubmitting(false);
                              });
                          }}
                          render={({ isSubmitting }) => (
                            <Form>
                              <FormikInput
                                name="name"
                                label="Name"
                                placeholder="Your Name"
                              />
                              <FormikInput
                                name="company"
                                label="Company Name"
                                placeholder="Your Company's Name (optional)"
                              />
                              <FormikInput
                                name="email"
                                label="Email Address"
                                placeholder="Your Email Address"
                              />
                              <FormikInput
                                name="phone"
                                label="Phone Number"
                                placeholder="Your Phone Number (optional)"
                              />
                              <FormikInput
                                component="textarea"
                                rows={3}
                                label="How can we help?"
                                name="message"
                              />
                              <div className="form-footer">
                                {this.state.error && (
                                  <div
                                    className="bg-red-lightest text-red-dark px-4 py-3 relative mb-4"
                                    role="alert"
                                  >
                                    <p className="block sm:inline text-red-darker">
                                      <strong className="font-serif tracking-wide uppercase">
                                        Whoops!
                                      </strong>
                                      <br />
                                      Looks like something happened. Please make
                                      sure all required fields are filled out
                                      and please try again.
                                    </p>
                                    <a
                                      className="absolute pin-t pin-b pin-r px-4 py-3 cursor-pointer"
                                      onClick={e => {
                                        e.preventDefault();
                                        this.setState({ error: false });
                                      }}
                                    >
                                      <svg
                                        className="fill-current h-6 w-6 text-red-darker"
                                        role="button"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <title>Close</title>
                                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                      </svg>
                                    </a>
                                  </div>
                                )}
                                <Button
                                  color="purple"
                                  type="submit"
                                  disabled={isSubmitting}
                                  style={{ opacity: isSubmitting ? 0.5 : 1 }}
                                >
                                  {isSubmitting ? 'Sending...' : 'Send Message'}
                                </Button>
                              </div>
                            </Form>
                          )}
                        />
                      </div>
                    )}
                  </Mutation>
                </div>
              )}
              {!this.state.showForm && (
                <div
                  className="w-full md:w-2/3 p-4 md:p-8 md:pl-12 items-center justify-center flex"
                  style={{ minHeight: this.state.height }}
                >
                  <div className="text-center">
                    <h3 className="font-serif tracking-wide uppercase mb-6">
                      Thank you for your message!
                    </h3>
                    <p>
                      We look forward to speaking with you and we will be in
                      touch shortly.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <GraphQLBlock />
      </Layout>
    );
  }
}

const CONTACT_MUTATION = gql`
  mutation SubmitContactForm($input: ContactFormInput!) {
    submitContactForm(input: $input) {
      id
    }
  }
`;

export default Contact;
